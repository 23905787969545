import React, { Component } from "react"
import Image2 from '../../images/about.jpg'

export class AboutSectionHomePg extends Component {
    render() {
        return (
            <section className="about py-12 md:py-16">
                <div className="container mx-auto">
                    <div className="flex flex-wrap">
                        <div className="lg:w-1/2 pr-4 pl-4">
                            <div className="section-subtitle">Our Story</div>
                            <div className="section-title">About <span>Kaylar</span></div>
                            <p>
                                At Kaylar Construction, we are your trusted general contractor specializing in a wide range of home improvement services. With extensive industry experience and a steadfast commitment to excellence, we have established ourselves
                                as leaders in the field. We prioritize superior craftsmanship and unparalleled customer service to ensure that every home renovation project exceeds expectations. Our team of skilled professionals is dedicated to staying ahead of design
                                trends and utilizing cutting-edge technology to deliver top-quality results.
                            </p>
                            <p>
                                We believe in a personalized approach, listening closely to your vision and collaborating closely with you throughout the process. Our mission is to create
                                functional and aesthetically pleasing spaces that reflect your unique style and enhance your lifestyle. Kaylar Construction is also dedicated to sustainability. We integrate eco-friendly materials and practices into our projects to promote
                                environmental responsibility while creating enduring, beautiful spaces for our clients. Discover how we can transform your home with our comprehensive and sustainable renovation solutions.
                            </p>
                            <ul className="listext list-unstyled mb-30">
                                <li>
                                    <div className="listext-icon"> <i className="norc-d-check"></i> </div>
                                    <div className="listext-text">
                                        <p>Over 15 years of experience</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="listext-icon"> <i className="norc-d-check"></i> </div>
                                    <div className="listext-text">
                                        <p>732+ successfully executed projects</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="listext-icon"> <i className="norc-d-check"></i> </div>
                                    <div className="listext-text">
                                        <p>Exceptional craftsmanship</p>
                                    </div>
                                </li>
                            </ul>
                            <div className="line-dec"></div>
                        </div>
                        <div className="lg:w-1/2 pr-4 pl-4 mx-auto">
                            <div className="about-img mt-12 lg:mt-40 xl:mt-32 mb-10 max-w-xl lg:max-w-2xl"> <img src={Image2} title='About Kaylar Construction' alt='About Kaylar Construction' />
                                <div className="about-img-hotifer">
                                    <p>Our approach to every project stands out for its unique perspective and expertise</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}