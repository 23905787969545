import * as React from "react"

function Windows() {
    return (

        <svg width="35px" height="35px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#FFFFFF">
            <g>
                <rect y="233.666" className="st0" width="35px" height="35px" />
                <path className="st0" d="M457,6.556h-78.562H133.562H51H0v213.992h68.805c21.531-41.382,47.711-143.367,59.054-189.992h256.282
                c11.343,46.625,37.523,148.61,59.054,189.992H512V6.556H457z"/>
                <path className="st0" d="M382.453,430.556H129.547l-64.79-151.641H0v217.883c0,0,24.282,19.453,68.805,0
                c44.523-19.454,44.523,15.562,89.046,0l-18.046-42.242h232.39l-18.046,42.242c44.523,15.562,44.523-19.454,89.046,0
                c44.523,19.453,68.805,0,68.805,0V278.915h-64.758L382.453,430.556z"/>
                <rect x="443.195" y="233.666" className="st0" width="68.805" height="32" />
                <rect x="262.664" y="107.222" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 298.3835 572.0344)" className="st0" width="9.999" height="233.997" />
                <rect x="295.879" y="225.346" transform="matrix(0.7071 0.7071 -0.7071 0.7071 304.7441 -123.027)" className="st0" width="9.999" height="161.999" />
            </g>
        </svg>
    )
}
export default Windows