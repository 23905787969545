import React, { Component } from "react"
import BannerImage from "../../images/home-main.png"
import Arrow from "../../images/arrow1.png"


export class ProcessSectionHomePg extends Component {
    render() {
        return (
            <section className="process">
                <div className="py-12 md:py-16 bg-img bg-fixed" style={{ backgroundImage: `url(${BannerImage})` }}>
                    <div className="container mx-auto">
                        <div className="flex flex-wrap">
                            <div className="md:w-1/2 pr-4 pl-4 md:mx-1/4 mb-45 text-center mx-auto">
                                <h5>How We Work</h5>
                                <h2>Our Process</h2>
                                <p>
                                    Experience our efficient and personalized approach to home improvement. We specialize in bathroom remodels, flooring, siding, kitchen remodels, new construction,
                                    painting, and window replacements. From the initial consultation to the final touches, our team is dedicated to delivering seamless, high-quality results tailored to your unique vision and needs.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-wrap grid grid-cols-2 md:flex">
                            <div className="md:w-1/4 pr-4 pl-4 padding mx-auto">
                                <div className="item text-center"> <img src={Arrow} className="tobotm" title='View Our Process' alt='View Our Process' /> <span className="icon norc-paper"></span>
                                    <h6 className="text-white flex md:block md:-ml-3"><span>1.</span>Tell Us About Your Project</h6>
                                </div>
                            </div>
                            <div className="md:w-1/4 pr-4 pl-4 padding mx-auto">
                                <div className="item text-center"> <img src={Arrow} title='View Our Process' alt='View Our Process' /> <span className="icon norc-pen-tool-2"></span>
                                    <h6 className="text-white flex md:block md:-ml-3"><span>2.</span>Schedule A Walk Through</h6>
                                </div>
                            </div>
                            <div className="md:w-1/4 pr-4 pl-4 padding mx-auto">
                                <div className="item text-center"> <img src={Arrow} className="tobotm" title='View Our Process' alt='View Our Process' /> <span className="icon norc-new-construction"></span>
                                    <h6 className="text-white flex md:block md:-ml-2"><span>3.</span>Receive An Estimate</h6>
                                </div>
                            </div>
                            <div className="md:w-1/4 pr-4 pl-4 padding mx-auto">
                                <div className="item text-center"> <span className="icon norc-trophy"></span>
                                    <h6 className="text-white flex md:block md:-ml-3"><span>4.</span>Begin The Project</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}