import * as React from "react"

function Bathroom() {
    return (
        <svg height="35px" width="800px" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512" fill="#FFFFFF">
            <g>
                <path className="st0" d="M295.496,72.738l-9.001-13.668c-5.142-7.814-15.404-10.39-23.627-5.941c0,0-16.586,6.569-30.616,16.596
		C145.637,131.649,70.488,217.502,70.488,217.502l31.484,32.894c74.239-92.21,99.128-81.713,111.12-78.764l99.038-73.612
		L295.496,72.738z"/>
                <path className="st0" d="M325.596,118.46l-8.804-13.36l-95.994,71.355l13.072,12.093c4.243,3.918,10.677,4.258,15.314,0.82
		l73.543-54.664C327.831,130.904,329.092,123.782,325.596,118.46z"/>
                <path className="st0" d="M49.229,246.288c-2.193,2.896-1.715,7.004,1.086,9.325l20.604,17.097c2.784,2.31,6.888,2.033,9.336-0.617
		l16.426-17.767l-30.153-30.946L49.229,246.288z"/>
                <path className="st0" d="M32.132,285.59c-7.893,13.914-15.914,32.554-21.977,56.123C4.088,365.259,0,393.714,0,427.172v33.873h23.999
		v-33.873c0-41.995,6.84-74.974,15.435-99.438c4.302-12.221,9.038-22.323,13.568-30.306c4.524-7.985,8.872-13.871,12.173-17.555
		l-17.889-16C42.586,269.133,37.402,276.308,32.132,285.59z"/>
                <path className="st0" d="M351.363,173.56c1.575-1.586,2.474-3.768,2.474-5.993c0-2.236-0.899-4.418-2.474-5.993
		c-1.581-1.576-3.763-2.481-5.993-2.481c-2.23,0-4.413,0.905-5.989,2.481c-1.575,1.575-2.48,3.757-2.48,5.993
		c0,2.225,0.905,4.407,2.48,5.993c1.576,1.575,3.758,2.47,5.989,2.47C347.6,176.03,349.782,175.135,351.363,173.56z"/>
                <path className="st0" d="M405.472,233.556c3.614-2.97,4.136-8.303,1.172-11.923c-2.976-3.608-8.308-4.13-11.923-1.171
		c-3.614,2.97-4.141,8.314-1.171,11.923C396.519,236.004,401.858,236.526,405.472,233.556z"/>
                <path className="st0" d="M419.897,252.1c-3.768,2.778-4.577,8.08-1.804,11.848c2.768,3.758,8.069,4.566,11.837,1.799
		c3.774-2.768,4.582-8.069,1.809-11.837C428.966,250.142,423.665,249.333,419.897,252.1z"/>
                <path className="st0" d="M495.27,358.893c-2.007-4.226-7.062-6.025-11.289-4.012c-4.221,2.011-6.02,7.057-4.008,11.283
		c2.007,4.226,7.064,6.015,11.29,4.014C495.484,368.165,497.277,363.109,495.27,358.893z"/>
                <path className="st0" d="M464.783,319.304c-4.072,2.31-5.504,7.474-3.205,11.55c2.304,4.066,7.474,5.504,11.545,3.193
		c4.072-2.299,5.51-7.472,3.204-11.538C474.022,318.441,468.854,317.005,464.783,319.304z"/>
                <path className="st0" d="M379.333,202.706c3.455-3.161,3.689-8.516,0.532-11.965c-3.161-3.449-8.521-3.683-11.97-0.533
		c-3.449,3.162-3.689,8.516-0.528,11.966C370.53,205.623,375.884,205.868,379.333,202.706z"/>
                <path className="st0" d="M443.322,285.047c-3.918,2.555-5.024,7.803-2.47,11.72c2.55,3.918,7.798,5.026,11.715,2.47
		c3.923-2.544,5.025-7.793,2.476-11.71C452.487,283.61,447.239,282.493,443.322,285.047z"/>
                <path className="st0" d="M511.446,396.663c-1.666-4.386-6.557-6.578-10.932-4.918c-4.37,1.671-6.563,6.558-4.902,10.932
		c1.66,4.376,6.557,6.569,10.927,4.908C510.908,405.924,513.107,401.028,511.446,396.663z"/>
                <path className="st0" d="M302.644,189.187c-1.58,1.575-2.485,3.758-2.485,5.982c0,2.236,0.905,4.418,2.485,5.993
		c1.576,1.575,3.758,2.481,5.984,2.481c2.234,0,4.411-0.906,5.993-2.481c1.575-1.575,2.48-3.758,2.48-5.993
		c0-2.225-0.904-4.407-2.48-5.982c-1.582-1.575-3.759-2.481-5.993-2.481C306.402,186.706,304.22,187.612,302.644,189.187z"/>
                <path className="st0" d="M403.716,374.904c-1.362-4.481-6.094-7.005-10.57-5.642c-4.477,1.363-6.999,6.089-5.637,10.57
		c1.363,4.482,6.095,6.994,10.565,5.642C402.55,384.101,405.078,379.374,403.716,374.904z"/>
                <path className="st0" d="M325.872,222.166c-4.04,2.352-5.408,7.537-3.05,11.582c2.352,4.045,7.537,5.408,11.582,3.054
		c4.04-2.352,5.408-7.537,3.056-11.582C335.102,221.176,329.918,219.814,325.872,222.166z"/>
                <path className="st0" d="M390.446,336.038c-1.655-4.376-6.536-6.59-10.916-4.929c-4.376,1.65-6.584,6.536-4.935,10.912
		c1.656,4.376,6.542,6.59,10.917,4.928C389.892,345.3,392.097,340.413,390.446,336.038z"/>
                <path className="st0" d="M404.115,408.245c-4.566,1.022-7.441,5.546-6.418,10.113c1.027,4.567,5.551,7.431,10.118,6.42
		c4.566-1.032,7.436-5.546,6.414-10.124C413.206,410.097,408.682,407.222,404.115,408.245z"/>
                <path className="st0" d="M345.715,257.508c-4.162,2.129-5.807,7.227-3.673,11.39c2.134,4.162,7.239,5.812,11.401,3.672
		c4.162-2.129,5.807-7.228,3.672-11.401C354.982,257.008,349.877,255.368,345.715,257.508z"/>
                <path className="st0" d="M374.824,298.119c-1.906-4.269-6.914-6.184-11.183-4.279c-4.274,1.906-6.195,6.909-4.289,11.188
		c1.911,4.269,6.914,6.185,11.187,4.28C374.814,307.402,376.73,302.399,374.824,298.119z"/>
                <path className="st0" d="M275.494,224.731c0-2.225-0.905-4.407-2.48-5.993c-1.576-1.575-3.758-2.469-5.988-2.469
		c-2.23,0-4.413,0.894-5.989,2.469c-1.58,1.586-2.48,3.768-2.48,5.993c0,2.236,0.899,4.418,2.48,5.993
		c1.576,1.576,3.759,2.481,5.989,2.481c2.229,0,4.411-0.905,5.988-2.481C274.589,229.149,275.494,226.967,275.494,224.731z"/>
                <path className="st0" d="M288.412,265.023c-1.252-4.503-5.914-7.143-10.422-5.897c-4.513,1.256-7.153,5.919-5.908,10.422
		c1.251,4.513,5.919,7.153,10.422,5.907C287.017,274.2,289.657,269.537,288.412,265.023z"/>
                <path className="st0" d="M309.601,434.326c-4.668,0.287-8.224,4.3-7.93,8.974c0.287,4.673,4.306,8.218,8.974,7.93
		c4.668-0.287,8.218-4.3,7.932-8.974C318.293,437.583,314.274,434.028,309.601,434.326z"/>
                <path className="st0" d="M314.508,397.387h0.006c-0.554-4.642-4.764-7.963-9.416-7.409c-4.641,0.554-7.957,4.758-7.409,9.41
		c0.554,4.642,4.769,7.963,9.411,7.409C311.746,406.244,315.062,402.028,314.508,397.387z"/>
                <path className="st0" d="M308.026,352.847c-0.794-4.621-5.168-7.708-9.783-6.92c-4.609,0.798-7.702,5.174-6.909,9.782
		c0.794,4.61,5.169,7.708,9.784,6.909C305.726,361.832,308.818,357.445,308.026,352.847z"/>
                <path className="st0" d="M299.307,308.7c-1.022-4.566-5.546-7.441-10.114-6.418c-4.566,1.022-7.441,5.536-6.418,10.113
		c1.016,4.556,5.546,7.431,10.113,6.408C297.45,317.792,300.329,313.268,299.307,308.7z"/>
            </g>
        </svg>
    )
}
export default Bathroom