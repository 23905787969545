import React, { Component } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export class BlogSectionHomePg extends Component {
    render() {
        const posts = this.props.data.edges

        return (
            <section className="news py-12 md:py-16">
                <div className="container mx-auto">
                    <div className="flex flex-wrap">
                        <div className="md:w-full pr-4 pl-4">
                            <div className="section-subtitle"><span>Latest News</span></div>
                            <div className="section-title">Our <span>Blog</span></div>
                        </div>
                    </div>
                    <div className="container mx-auto py-7 lg:py-8 px-4 pb-9 lg:pb-12">
                        <div className="mx-auto grid gap-x-8 lg:grid-cols-2 xl:grid-cols-3 gap-y-8">
                            {posts && posts.map(({ node: post }) => {
                                const title = post.title
                                const image = getImage(post.image)
                                let blogCardTitle = post.title
                                return (
                                    <div className="flex flex-col mx-auto border rounded-lg shadow-xl mb-3 xl:mb-12 bg-white" key={post.slug}>
                                        <article>
                                            <div className="max-w-md mx-auto hover:opacity-80 rounded-lg lg:h-[36.5rem] xl:h-[36rem]">
                                                <Link to={post.slug} title={blogCardTitle} alt={blogCardTitle}>
                                                    <GatsbyImage className="h-56 rounded-t-lg z-0" src="" image={image} title={blogCardTitle} alt={blogCardTitle} />
                                                </Link>
                                                <div className="px-5 py-5">
                                                    <p className="font-medium text-gray-600 uppercase text-sm">{post.category}</p>
                                                    <Link to={post.slug} title={blogCardTitle} alt={blogCardTitle}>
                                                        <h3 className="pt-3 font-bold text-lg lg:h-[4rem]">{title}</h3>
                                                        <p className="py-3 text-base lg:h-[6rem]">{post.description}</p>
                                                    </Link>
                                                    <p>{post.author}</p>
                                                    <p className="pb-2 pt-1">{post.date}</p>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}