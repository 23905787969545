import React, { Component } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export class ProjectsSectionProjectPg extends Component {
    render() {
        const projectpage = this.props.data

        function shorten(str, maxLen, separator = '') {
            if (str.length <= maxLen) return str;
            return str.substr(0, str.lastIndexOf(separator, maxLen)) + '...';
        }

        return (
            <section className="projects py-12 md:py-16">
                <div className="container mx-auto -mb-16">
                    <div className="flex flex-wrap">
                        {projectpage.map((project, index) => (
                            <div key={project.id} className="md:w-full pr-4 pl-4 mb-10">
                                <div className={`projects ${index % 2 === 0 ? "left" : ""}`}>
                                    <figure><GatsbyImage image={project.node.mainImage.gatsbyImageData} className="img-fluid" placeholder="none" title={project.node.mainImage.title} alt={project.node.mainImage.title} /></figure>
                                    <div className="caption">
                                        <h4>{project.node.serviceName} <span>in {project.node.city}</span></h4>
                                        <p>{shorten(project.node.paragraph1.paragraph1, 150)}</p>
                                        <div className="line-dec"></div>
                                        <div className="info-wrapper">
                                            <div className="date"><i className="norc-new-construction"></i>{project.node.serviceName}</div>
                                            <div className="more"><Link to={project.node.slug} className="link-btn" title={'View This ' + project.node.serviceName + ' Project In ' + project.node.city} alt={'View ' + project.node.serviceName + ' In ' + project.node.city} >Discover</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        )
    }
}