import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { MainSectionHomePg } from "../components/Home-Page/main-section-homePg"
import { AboutSectionHomePg } from "../components/Home-Page/about-section-homePg"
import { ServicesSectionHomePg } from "../components/Home-Page/services-section-homePg"
import { ProcessSectionHomePg } from "../components/Home-Page/process-section-homePg"
import { ProjectsSectionHomePg } from "../components/Home-Page/projects-section-homePg"
import { CoreValuesSectionHomePg } from "../components/Home-Page/core-values-section-homePg"
import { MetricsSectionHomePg } from "../components/Home-Page/metrics-section-homePg"
import { BlogSectionHomePg } from "../components/Home-Page/blog-section-homePg"

const IndexPage = (props) => {
  const data = useStaticQuery(graphql`
  query {
    allContentfulBlog(limit: 3, sort: { fields: date, order: DESC }) {
      edges{
          node{
              title
              category
              slug
              author
              body {
                  body
              }
              description
              date(formatString: "MMMM Do, YYYY")
              image {
                 gatsbyImageData(
                     width: 600
                     placeholder: NONE
                     formats: [AUTO, WEBP, AVIF]
                 )
                 title
                 description
              }
          }
      }
    },
    allContentfulProjects(limit: 4, sort: { fields: date, order: ASC }) {
      edges{
        node{
          slug
          mainImage {
              title
              description
              url
              gatsbyImageData (
                placeholder: NONE
                quality: 100
              )
            }
          city
          serviceName
          paragraph1{
              paragraph1
          }
          sliderImages {
              id
              description
              gatsbyImageData
              url
              title
              description
          }
          date
      }
    }
  }
}
`)
  return (
    <Layout>
      <Seo
        title="Kaylar Construction: Premier Home Remodeling and Design Services for Kitchens, Bathrooms, Siding, Windows & Doors, Decks & Patios, and Interior Design"
        description="Welcome to our general contracting services website! Transform your home with expert kitchen remodeling, bathroom remodeling, siding, windows & doors, decks & patios, and interior design. Explore our services and contact us today for a consultation."
        location={props.location.pathname}
        keywords={[
          'Kitchen remodeling',
          'Bathroom remodeling',
          'Siding',
          'Windows and doors',
          'Decks and patios',
          'Interior design services'
        ]}
      />
      <MainSectionHomePg />
      <ServicesSectionHomePg />
      <AboutSectionHomePg />
      <ProcessSectionHomePg />
      <ProjectsSectionHomePg data={data.allContentfulProjects} />
      <CoreValuesSectionHomePg />
      <MetricsSectionHomePg />
      <BlogSectionHomePg data={data.allContentfulBlog} />
    </Layout>
  )
}
export default IndexPage


