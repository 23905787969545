import React, { Component } from "react"
import { Button } from "../button"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Image from '../../images/home-main.png'

export class MainSectionHomePg extends Component {
    render() {
        return (
            <header className="header">
                <div className="text-left">
                    <div className="caption">
                        <div className="container mx-auto">
                            <div className="relative">
                                <div className="absolute text-white top-[13rem] md:top-[12.5rem] lg:top-56 md:w-3/4 pr-4 pl-4">
                                    <h4>Top Quality Guaranteed</h4>
                                    <h1>Kaylar Construction</h1>
                                    <p>
                                        We are your premier choice for comprehensive construction services in and around the greater Sacramento area. Specializing in bathroom remodels, kitchen remodels, expert painting services, and siding services, including dry rot repairs.
                                    </p>
                                    <p className="hidden md:flex">
                                        At Kaylar Construction, we have a strong commitment to quality craftsmanship and ensuring client satisfaction. We take pride in our specialty of turning visions into reality and invite you to contact us today to discuss your project and allow us the opportunity to exceed your expectations.
                                    </p>
                                    <div className="flex space-x-5">
                                        <AnchorLink to='/#services'>
                                            <button className='relative h-10 py-2 px-5 transition-colors rounded-lg border focus:shadow-outline hover:shadow-lg font-semibold transition duration-300 ease-in-out text-sm border-darkblue bg-darkblue text-white hover:bg-lightblue hover:text-white' title='View Our Services' alt="View Our Services - Kaylar Construction">
                                                <span>Our Services</span>
                                            </button>
                                        </AnchorLink>
                                        <Button buttonPage="/contact" buttonText="Contact Us" lightBlue={true} />
                                    </div>
                                    <div className="text-white pt-6">
                                        <a href="tel:+19162450073" className="hover:text-lightblue transition duration-300 ease-in-out" title='Call Kaylar Construction' alt='Call Kaylar Construction'><span className="fa fa-phone"></span> (916) 245-0073</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="object-cover h-[680px] sm:h-[730px] md:h-[800px] lg:h-[800px] xl:h-[770px] w-full" src={Image} title="Welcome to Kaylar Construction" alt="Welcome to Kaylar Construction" />
            </header>
        )
    }
}