import React, { Component } from "react"
import { ProjectsSectionProjectPg } from "../../components/Projects-Page/projects-section-projectPg"

export class ProjectsSectionHomePg extends Component {
    render() {
        return (
            <section className="projects py-12 md:py-16">
                <div className="container mx-auto">
                    <div className="flex flex-wrap">
                        <div className="md:w-full pr-4 pl-4">
                            <div className="section-subtitle">Latest Works</div>
                            <div className="section-title">Our <span>Projects</span></div>
                        </div>
                    </div>
                    <div className="-mb-24">
                        <ProjectsSectionProjectPg data={this.props.data.edges} />
                    </div>
                </div>
            </section>
        )
    }
}